import {
  Spin,
  Result,
  Typography,
  Space,
  Descriptions,
  Tabs,
  Button,
} from "antd";
import styles from "../../main/App.less";
import { useEffect, useState } from "react";
import { gCheck } from "../../tool/Helper";
import ImageSelect from "../ImageSelect/ImageSelect";
import BaiduMap from '../BaiduMap/BaiduMap';

import axios from "axios";
import Fingerprint2 from "fingerprintjs2sync";

const Item = Descriptions.Item;

const DescripOps = {
  bordered: true,
  column: 1,
  size: "small",
  labelStyle: { width: "1.3rem" },
};

export default function OperationFun() {
  const [location, setLocation] = useState();
  const [locationErrorInfo, setLocationErrorInfo] = useState();

  const [errorObj, setErrorObj] = useState({});
  const [infoObj, setInfoObj] = useState({});

  const [recordInfo, setRecordInfo] = useState()

  function getLocation() {
    if (navigator.geolocation) {

      // console.log(navigator.permissions)

      navigator.geolocation.getCurrentPosition((success)=> {
        console.log("geolocation success", success)
        showPosition(success)
        setInfoObj({ ...errorObj, location_error: '' });
      }, (err)=> {
        console.error("geolocation err", err)
        setLocationErrorInfo("定位功能已禁用");
        setInfoObj({ ...errorObj, location_error: "定位功能已禁用" });

        navigator.permissions.query({ name: 'geolocation' })
        .then(function(permissionStatus) {
          // permissionStatus.state
          // 申请 定位权限
          console.log(permissionStatus);
          alert(permissionStatus.name + ', ' + permissionStatus.state);
        })
        .catch(function(error) {
          // handle error
        });

      }, { enableHighAccuracy: true });
    } else {
      console.log("Geolocation is not supported by this browser.");
      setErrorObj({ ...errorObj, location_error: "定位功能异常" });
      setLocationErrorInfo("定位功能异常");
    }
  }

  function showPosition(position) {
    console.log('position', position)
    console.log(
      "Latitude: " +
        position.coords.latitude +
        "<br>Longitude: " +
        position.coords.longitude
    );
    if (position && position.coords) {
      setLocation({
        lat: position.coords.latitude,
        lon: position.coords.longitude,
      });
    }
  }

  function getRecord() {
    // 检查浏览器是否支持 Web Audio API 和 MediaStream Recording API
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      // 请求获取用户音频输入设备的访问权限
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(function (stream) {
          // 创建 MediaRecorder 对象来录制音频
          const mediaRecorder = new MediaRecorder(stream);
          const chunks = [];

          // 监听音频数据可用事件
          mediaRecorder.addEventListener("dataavailable", function (e) {
            chunks.push(e.data);
          });

          // 监听录制完成事件
          mediaRecorder.addEventListener("stop", function () {
            // 将音频数据转换为 Blob 对象
            const audioBlob = new Blob(chunks, { type: "audio/wav" });

            // 创建一个音频元素来播放录制的音频
            const audioElement = document.createElement("audio");
            audioElement.src = URL.createObjectURL(audioBlob);
            audioElement.controls = true;
            document.getElementById("media_record").appendChild(audioElement);
            // document.body.appendChild(audioElement);
          });

          // 开始录制音频
          mediaRecorder.start();

          // 录制一段时间后停止
          setTimeout(function () {
            mediaRecorder.stop();
            setRecordInfo("");
            setInfoObj({ ...infoObj, take_recorder_error: "" });
          }, 5000);
        })
        .catch(function (err) {
          setRecordInfo("无法获取音频设备访问权限");
          console.error("无法获取音频设备访问权限:", err);
          setInfoObj({ ...infoObj, take_recorder_error: "无法获取音频设备访问权限" });
        });
    } else {
      console.error("浏览器不支持音频录制功能");
      setRecordInfo("浏览器不支持音频录制功能")
      setErrorObj({ ...errorObj, take_recorder_error: "浏览器不支持音频录制功能" });
    }
  }

  function callback(res) {
    // 第一个参数传入回调结果，结果如下：
    // ret         Int       验证结果，0：验证成功。2：用户主动关闭验证码。
    // ticket      String    验证成功的票据，当且仅当 ret = 0 时 ticket 有值。
    // CaptchaAppId       String    验证码应用ID。
    // bizState    Any       自定义透传参数。
    // randstr     String    本次验证的随机串，后续票据校验时需传递该参数。
    console.log("callback:", res);

    // res（用户主动关闭验证码）= {ret: 2, ticket: null}
    // res（验证成功） = {ret: 0, ticket: "String", randstr: "String"}
    // res（请求验证码发生错误，验证码自动返回terror_前缀的容灾票据） = {ret: 0, ticket: "String", randstr: "String",  errorCode: Number, errorMessage: "String"}
    // 此处代码仅为验证结果的展示示例，真实业务接入，建议基于ticket和errorCode情况做不同的业务处理
    if (res.ret === 0) {
      setInfoObj({ ...infoObj, ticket_error: "验证通过" });


      // 复制结果至剪切板
      // var str =
      //   "【randstr】->【" +
      //   res.randstr +
      //   "】      【ticket】->【" +
      //   res.ticket +
      //   "】";
      // var ipt = document.createElement("input");
      // ipt.value = str;
      // document.body.appendChild(ipt);
      // ipt.select();
      // document.execCommand("Copy");
      // document.body.removeChild(ipt);
      // alert(
      //   "1. 返回结果（randstr、ticket）已复制到剪切板，ctrl+v 查看。 2. 打开浏览器控制台，查看完整返回结果。"
      // );
    }
  }

  // 定义验证码js加载错误处理函数
  function loadErrorCallback() {
    var appid = "您的CaptchaAppId";
    // 生成容灾票据或自行做其它处理
    var ticket =
      "terror_1001_" + appid + "_" + Math.floor(new Date().getTime() / 1000);
    callback({
      ret: 0,
      randstr: "@" + Math.random().toString(36).substr(2),
      ticket: ticket,
      errorCode: 1001,
      errorMessage: "jsload_error",
    });
  }

  return (
    <div className={styles.content}>
      <div className={styles.contentTitle}>
        <span>操作类功能性信息</span>
        <Space>
          {errorObj && errorObj.length ? (
            <span style={{ color: "red" }}>
              {Object.keys(errorObj).length}个错误
            </span>
          ) : null}
        </Space>
      </div>

      <Descriptions {...DescripOps}>
        <Item label={"选择图片功能"}>
          <div className={styles.betweenFlex}>
            <div className={styles.flexBox}>
              <ImageSelect />
            </div>
            {/* {gCheck("pick_photo_error", { errorObj, infoObj })} */}
          </div>
        </Item>

        <Item label={"拍照功能"}>
          <div className={styles.betweenFlex}>
            <div className={styles.flexBox}>拍照</div>
            {/* {gCheck("take_photo_error", { errorObj, infoObj })} */}
          </div>
        </Item>

        <Item label={"录音功能"}>
          <div className={styles.betweenFlex}>
            <div className={styles.flexBox}>
              <Button onClick={getRecord}> 录音 </Button>
              <div id='media_record'></div>
            </div>
            { recordInfo? gCheck("take_recorder_error", { errorObj, infoObj }) : recordInfo }
          </div>
        </Item>

        <Item label={"定位功能"}>
          <div className={styles.betweenFlex}>
            <div className={styles.flexBox}>
              <Button onClick={getLocation}> 定位 </Button>
              {location ? (
                // <GaodeMapReverseGeocoding
                //   latitude={36.675807}
                //   longitude={117.000923}
                // />
                <BaiduMap  
                latitude={location.lat}
                longitude={location.lon}/>
              ) : null}

              {location ? `${location.lat} ${location.lon}` : null}
            </div>
            { locationErrorInfo ? gCheck("location_error", { errorObj, infoObj}): null}
          </div>
        </Item>
        <Item label={"视频播放"}>
          <div className={styles.betweenFlex}>
            <div className={styles.flexBox}>
              <video controls width='320' height='240'>
                <source src='./video/1.mp4' type='video/mp4' />
              </video>
            </div>
            {gCheck("media_error", { errorObj, infoObj })}
          </div>
        </Item>
        <Item label={"a 标签"}>
          <div className={styles.betweenFlex}>
            <div className={styles.flexBox}><a href="https://www.fwgcloud.com/">去官网</a></div>      
          </div>
        </Item>
      </Descriptions>
    </div>
  );
}
