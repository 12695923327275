import { useState, useEffect } from "react";
// @ts-ignore
import Flag from "./Flag";
import axios from "axios";

export default function IpFlag(props) {
  const { ip } = props;
  const [code, setCode] = useState();

  useEffect(() => {
    if (ip) {
      axios.get(`/ipInfo?ip=${ip}`).then((res) => {
        console.log(res)
        const countryCode = res.data?.data?.country_code;
        setCode(countryCode);
      }).catch(res=> {
        console.error("通过IP获取IP信息失败:" ,  '接口/ipInfo,', '参数：', ip)
      });
    }
  }, [ip]);

  return code ? <Flag code={code} /> : null;
}
