import React, {Component} from "react";

window.___ = {
    changeUrl(data){
        let url = "";
        if (data) {
            url += "?";
            for (let key in data) {
                url = url + key + "=" + data[key] + "&"
            }

            url = url.substring(0, url.length - 1)
        }

        window.history.replaceState({},null,url);
    },

    getParams() {
        let url = decodeURIComponent(window.location.href);
        let index = url.lastIndexOf('?');
        let query = url.slice(index + 1);
        let vars = query.split("&");
        let obj = {};
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            obj[pair[0]] = pair[1]
        }
        return obj;
    },
};