import './___.js'
import React, {Component} from "react";
import ReactDOM from "react-dom";
import {message, ConfigProvider} from "antd";
import CookieHelp from "./tool/CookieHelp";
import HttpTool from "./tool/HttpTool";
import Config from './config/config';
import moment from 'moment';
import 'moment/locale/zh-cn';
import zhCN from 'antd/lib/locale/zh_CN';
import App from "./main/App";

//配置
moment.locale('zh-cn');

CookieHelp.userCookieKey = Config.projectUniqueName;
HttpTool.authCookieName = Config.projectUniqueName + "_AUTH";
HttpTool.timeout = Config.requestTimeout;
HttpTool.setEncrypt(Config.publicKey);

message.config({
    top: window.screen.height / 3,
});

window["_NOENCRYPT"] = JSON.stringify(['/base-usercenter/']);
window["_CONCAT_API"] = '/api';

//加载外部js

ReactDOM.render(
    <ConfigProvider
        locale={zhCN}
    >
        <App/>
    </ConfigProvider>,
    document.getElementById("root")
);