import axios from "axios";
import { timezone, getLocalTime, getLanguage } from "../tool/LocalInfo";

// WebRTC IPs
const iceServers = [
  { urls: "stun:stun.l.google.com:19302" },
  // { urls: "stun:stun1.l.google.com:19302" },
  // { urls: "stun:stun2.l.google.com:19302" },
  // { urls: "stun:stun3.l.google.com:19302" },
  // { urls: "stun:stun4.l.google.com:19302" },
];



// getUserIPs function
function getUserIPs() {
  return new Promise((resolve, reject) => {
    /**
     * 保存临时的
     */
    let ipAddress = [];  // 处理穿透到内网IP问题

    let ips = {};
    const myPeerConnection = new RTCPeerConnection({ iceServers });
    myPeerConnection.createDataChannel("");
    myPeerConnection
      .createOffer()
      .then((offer) => myPeerConnection.setLocalDescription(offer));

    myPeerConnection.onicecandidate = function (event) {
      if (event.candidate) {
        // const parts = event.candidate.candidate.split(" ");
        console.log(event);

        const ip = event.candidate.address;
        const relatedAddress  = event.candidate.relatedAddress;

        ipAddress.push({ip, relatedAddress})

        console.log(ipAddress)
        if (ipv4Regex.test(ip)) {
          ips.ipv4 = ip;
        } else if (ipv6Regex.test(ip)) {
          ips.ipv6 = ip;
        } else {
          ips.ip = ip;
        }

        if (ips.ipv4 || ips.ipv6) {
          // 更新IP信息，清理本地IP
          if(ipAddress.length > 0) {
            ipAddress.forEach(ipsInfo => {
              if (ipsInfo && ipsInfo?.relatedAddress === ips.ipv4) {
                ips.ipv4 = ipsInfo.ipv4
              }
            })
          }
          setTimeout(()=> {
            resolve(ips)
          }, 3500)
        }         
      }
    };

    myPeerConnection.onicegatheringstatechange = (state) => {

      console.log("icegatheringsstate", state)

      switch(state.iceGatheringState) {
        case "new":
          /* gathering is either just starting or has been reset */
          break;
        case "gathering":
          /* gathering has begun or is ongoing */
          break;
        case "complete":
          /* gathering has ended */

          console.log('--- 链接 成功 ----')
          break;
        default:
          break;
      }
    }
  });
}

const ipv4Regex =
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
const ipv6Regex =
  /^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}(([0-9a-fA-F]{1,4}:){1,4}|((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))$/;

/**
 * 获取 ip 地址的相关信息
 */
async function getIpAddressLocationInfo(callback) {
  let myAddress = {}
  let ips = await getUserIPs();

  myAddress.ipv4 = ips.ipv4;
  myAddress.ipv6 = ips.ipv6;

  console.log("实际IP", myAddress.ipv4)

  let localInfo = getLocalInfo();

  myAddress.realInfo = {
    ipv4 : ips.ipv4,
    ipv6 : ips.ipv6,
    ...localInfo
  }

  const ipInfo = await axios.get("/ip");

  const data = ipInfo.data.data;

  console.log("ip info ", data);

  myAddress.ipInfo = data;

  let errorObj = {};
  let warnObj = {};
  let infoObj = {};

  // IP 信息是否匹配
  console.log("实际IP地址", myAddress.ipv4, "代理IP地址", data.clientIp);
  if (data.clientIp !== myAddress.ipv4) {
    errorObj["clientIp"] = "本机IP与访问IP不一致，开启了代理";
  }

  console.log("本机时区", localInfo?.tz, "访问IP时区", data?.tz);

  // 时区 是否匹配
  if (data.tz && (data.tz.toLowerCase() !== localInfo.tz.toLowerCase())) {
    errorObj['tz'] = '本机时区与访问IP时区不一致';
  }

  // 位置距离
  // getLocation();
  const score = data?.score;

  if (score <= 20 && score >= 0) {
    // infoObj['ip_score'] = '优质IP'
  } else if (score <= 50 && score > 20) {
    infoObj['ip_score'] = '一般'
  } else if (score > 50 && score < 80) {
    warnObj['ip_score'] = '疑似VPN'
  } else if (score >= 80 && score <= 100) {
    errorObj['ip_score'] = 'VPN'
  } else {
    tips = '评分未知'
  }


  callback({
    myAddress,
    errorObj: errorObj,
    warnObj: warnObj,
    infoObj: infoObj,
    errorNum: Object.keys(errorObj).length,
    warnNum: Object.keys(warnObj).length,
  });
}

function getLocalInfo() {
  const tz = timezone();

  console.log("时区", tz);

  const languages = getLanguage();
  console.log("浏览器语言", languages);

  const time = getLocalTime();

  console.log("当前时间", time);

  return { tz, language: languages, time}
}


const EARTH_RADIUS = 6378.137;
/**
 * https://www.zhihu.com/question/265407371/answer/437817362
	 * 根据两点间经纬度坐标（double值），计算两点间距离，单位为米
	 */
function GetDistance( lat1,  lng1,  lat2,  lng2) {
  const radLat1 = rad(lat1);
  const radLat2 = rad(lat2);
  const a = radLat1 - radLat2;
  const b = rad(lng1) - rad(lng2);
  let s = 2 * Math.asin(Math.sqrt(
      Math.pow(Math.sin(a / 2), 2) + Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)));
  s = s * EARTH_RADIUS;
  s = (s * 10000) / 10;
  return s;
}







export { getUserIPs, getIpAddressLocationInfo };
