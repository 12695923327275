import { Space, Descriptions, Tabs } from "antd";
import styles from "../../main/App.less";
import { useEffect, useState } from "react";
import Dexie from "dexie";

import { gCheck } from "../../tool/Helper";

const Item = Descriptions.Item;

const DescripOps = {
  bordered: true,
  column: 1,
  size: "small",
  labelStyle: { width: "1.3rem" },
};

export default function Cookie() {
  const [myCookie, setMyCookie] = useState();
  const [storage, setStorage] = useState();
  const [sIndexDB, setsIndexDB] = useState();
  const [sStorage, setSStorage] = useState();

  const [errorObj, setErrorObj] = useState({});
  const [infoObj, setInfoObj] = useState({});

  useEffect(() => {
    checkCookieFun();

    checkStorageFun();

    checkSessionStorageFun();

    checkIndexedDBFun();
  }, []);

  function parseCookie() {
    var cookieString = document.cookie;
    var cookies = {};
  
    // 将cookie字符串拆分成单个cookie
    var cookieArray = cookieString.split(';');
  
    // 遍历每个cookie并解析键值对
    for (var i = 0; i < cookieArray.length; i++) {
      var cookie = cookieArray[i].trim();
      var delimiterIndex = cookie.indexOf('=');
  
      // 解析键值对
      var cookieName = cookie.substring(0, delimiterIndex);
      var cookieValue = cookie.substring(delimiterIndex + 1);
  
      // 存储到cookies对象中
      cookies[cookieName] = cookieValue;
    }
  
    return cookies;
  }

  const checkCookieFun = () => {
    const cookie = document.cookie;
    if (cookie) {
      const cookies = parseCookie();
      const myCookie = cookies?.uuid;
      setMyCookie(myCookie);
    } else {
      setErrorObj({ ...errorObj, cookie_error: "cookie异常" });
    }
  };

  const checkStorageFun = () => {
    const randomValue = Math.random().toString();
    let value;
    try {
      value = localStorage.getItem("storage");

      if (value) {
        setStorage(value);
      } else {
        localStorage.setItem("storage", randomValue);
        setStorage(randomValue);
      }

      setInfoObj({ ...infoObj, storage_error: "storage 读写正常" });
    } catch (e) {
      value = "";
      setErrorObj({ ...errorObj, storage_error: e });
    }

    return value;
  };

  const checkSessionStorageFun = () => {
    const randomValue = Math.random().toString();
    let sValue = "";
    try {
      sValue = sessionStorage.getItem("sessionstorage");

      if (sValue) {
        setSStorage(sValue);
      } else {
        sessionStorage.setItem("sessionstorage", randomValue);
        setSStorage(sValue);
      }
      // setInfoObj({ ...infoObj, session_storage_error: "sessionStorage读写正常" });
    } catch (e) {
      sValue = "session storage 读写错误";
      console.error(e);
      setErrorObj({ ...errorObj, session_storage_error: sValue });
    }
  };

  const checkIndexedDBFun = () => {
    return new Promise(async (resolve, reject) => {
      const db = new Dexie("MyDatabase");

      db.version(1).stores({
        tasks: "++id, uuid",
      });

      // 查询数据
      console.log("查询第一条数据");
      const oldTask = await db.tasks.get(1);
      console.log(oldTask);

      setsIndexDB(oldTask?.uuid)

      // 增加数据
      db.tasks.add({
        uuid: Math.random().toString()
      });
      
      
      // 高级查询,查询所有ID大于0的
      const allTask = await db.tasks.where("id").above(0).toArray();
      console.log("查询所有的数据");
      console.log(allTask);

      resolve(true);
    });
  };

  return (
    <div className={styles.content}>
      <div className={styles.contentTitle}>
        <span>基础数据类信息</span>
        <Space>
          {errorObj && errorObj.length ? (
            <span style={{ color: "red" }}>
              {Object.keys(errorObj).length}个错误
            </span>
          ) : null}
        </Space>
      </div>

      <Descriptions {...DescripOps}>
        <Item label={"Cookie 功能"}>
          <div className={styles.betweenFlex}>
            <div className={styles.flexBox}>{myCookie}</div>
            {gCheck("cookie_error", { errorObj, infoObj })}
          </div>
        </Item>
        <Item label={"localStorage 功能"}>
          <div className={styles.betweenFlex}>
            <div className={styles.flexBox}>{storage}</div>
            {gCheck("storage_error", { errorObj, infoObj })}
          </div>
        </Item>
        <Item label={"sessionStorage 功能"}>
          <div className={styles.betweenFlex}>
            <div className={styles.flexBox}>{sStorage}</div>
            {gCheck("session_storage_error", { errorObj, infoObj })}
          </div>
        </Item>
        <Item label={"indexedDB 功能"}>
          <div className={styles.betweenFlex}>
            <div className={styles.flexBox}>{sIndexDB}</div>
            {gCheck("indexedDB_error", { errorObj, infoObj })}
          </div>
        </Item>
      </Descriptions>
    </div>
  );
}
