
/**
 * 获取时区
 */
function timezone() {
   return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

/**
 * 获取时间
 */

function getLocalTime() {
    return new Date()
}

/**
 * 获取浏览器语言
 */
function getLanguage() {
  return  navigator.language
}

export { timezone, getLocalTime, getLanguage}

