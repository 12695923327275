import { Spin, Result, Typography, Space, Descriptions, Tabs } from "antd";

import {
  CloseCircleFilled,
  WarningFilled,
  CheckCircleFilled,
  InfoCircleFilled,
} from "@ant-design/icons";

  //每一项判断是否错误
  function gCheck(name, Obj) {
    if (Array.isArray(name)) {
      return getItemArrayCheck(name, Obj);
    } else {
      return getItemArrayCheck([name], Obj);
    }
  }

  //多项判断是否错误
  function getItemArrayCheck(nameArray, Obj) {
    let { errorObj, warnObj, infoObj } = Obj;
    // let errorObj = Obj;

    console.log("错误条数", errorObj)

    console.log("正常的信息提示", infoObj);

    let err, warn, info;

    nameArray.forEach((name) => {
      if (errorObj[name]) {
        err = errorObj[name];
      }

      // if (warnObj[name]) {
      //   warn = warnObj[name];
      // }

      if (infoObj[name]) {
        info = infoObj[name];
      }
    });

    if (err) {
      return (
        <Space style={{ color: "red" }}>
          <span>{err}</span>
          <CloseCircleFilled />
        </Space>
      );
    }

    // if (warn) {
    //   return (
    //     <Space style={{ color: "darkorange" }}>
    //       <span>{warn}</span>
    //       <WarningFilled />
    //     </Space>
    //   );
    // }

    if (info) {
      return (
        <Space style={{ color: "dodgerblue" }}>
          <span>{info}</span>
          <InfoCircleFilled />
        </Space>
      );
    }

    return <CheckCircleFilled style={{ color: "limegreen" }} />;
  }

  export { gCheck }