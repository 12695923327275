import Fingerprint2 from "fingerprintjs2sync";
import Webping from "web-pingjs";
import axios from "axios";
import Dexie from "dexie";

const UAParser = require("ua-parser-js");

class AnalyseHelp {
  constructor() {
    this.userAgent = navigator?.userAgent || "";
    this.deviceTypeAnalyse = this.getDeviceType(this.userAgent);
  }

  //通过canvas生成id
  getIdByCanvas() {
    let canvas = document.createElement("canvas");
    let ctx = canvas.getContext("2d");
    let txt = "web_visitanalyse"; //这个字段改变，同一设备生成的id会变
    ctx.textBaseline = "top";
    ctx.font = "14px 'Arial'";
    ctx.textBaseline = "tencent";
    ctx.fillStyle = "#f60";
    ctx.fillRect(125, 1, 62, 20);
    ctx.fillStyle = "#069";
    ctx.fillText(txt, 2, 15);
    ctx.fillStyle = "rgba(102, 204, 0, 0.7)";
    ctx.fillText(txt, 4, 17);

    let b64 = canvas.toDataURL().replace("data:image/png;base64,", "");
    let bin = atob(b64);
    let crc = this.bin2hex(bin.slice(-16, -12));
    //let crc = bin.slice(-16,-12);
    return crc;
  }

  bin2hex(str) {
    let result = "";
    for (let i = 0; i < str.length; i++) {
      result += this.int16_to_hex(str.charCodeAt(i));
    }
    return result;
  }

  int16_to_hex(i) {
    let result = i.toString(16);
    let j = 0;
    while (j + result.length < 4) {
      result = "0" + result;
      j++;
    }
    return result;
  }

  //通过Fingerprint插件生成id
  getIdByFPrint() {
    return new Fingerprint2().getSync().fprint;
  }

  async getCookie() {
    let myCookie;

    const oldCookie = document.cookie;
    const cookies = this.parseCookie();

    if (oldCookie && cookies?.uuid) {
      myCookie = cookies?.uuid;
    } else {
      // const uuid = this.getIdByFPrint();
      const uuid = Math.random().toString();
      // 向服务器发送请求
      await axios.get("/cookie?uuid=" + uuid).then((res) => console.log(res));
      const cookie = document.cookie;

      if (cookie) {
        const cookies = this.parseCookie();
        myCookie = cookies?.uuid;
      }
    }

    console.log("get cookie", myCookie);

    return myCookie;
  }

  parseCookie() {
    var cookieString = document.cookie;
    var cookies = {};

    // 将cookie字符串拆分成单个cookie
    var cookieArray = cookieString.split(";");

    // 遍历每个cookie并解析键值对
    for (var i = 0; i < cookieArray.length; i++) {
      var cookie = cookieArray[i].trim();
      var delimiterIndex = cookie.indexOf("=");

      // 解析键值对
      var cookieName = cookie.substring(0, delimiterIndex);
      var cookieValue = cookie.substring(delimiterIndex + 1);

      // 存储到cookies对象中
      cookies[cookieName] = cookieValue;
    }

    return cookies;
  }

  checkCookieFun() {
    let myCookie;
    const cookie = document.cookie;
    if (cookie) {
      const cookies = parseCookie();
      myCookie = cookies?.uuid;
    }

    return myCookie;
  }

  getStorage() {
    const randomValue = Math.random().toString();
    let value = localStorage.getItem("storage");

    if (value) {
      return value;
    } else {
      localStorage.setItem("storage", randomValue);
    }

    return value;
  }

  getSessionStorage() {
    const randomValue = Math.random().toString();
    let sValue = sessionStorage.getItem("sessionstorage");

    if (!sValue) {
      sessionStorage.setItem("sessionstorage", randomValue);
    }
    return sValue;
  }

  getIndexedDB() {
    return new Promise(async (resolve, reject) => {
      const db = new Dexie("MyDatabase");

      db.version(1).stores({
        tasks: "++id, uuid",
      });

      // 查询数据
      console.log("查询第一条数据");
      const oldTask = await db.tasks.get(1);
      console.log(oldTask);

      // 增加数据
      db.tasks.add({
        uuid: Math.random().toString(),
      });

      // 高级查询,查询所有ID大于0的
      const allTask = await db.tasks.where("id").above(0).toArray();
      console.log("查询所有的数据");
      console.log(allTask);

      resolve(oldTask?.uuid);
    });
  }

  //分析设备类型
  getDeviceType(u) {
    return {
      //移动终端浏览器版本信息
      trident: u.indexOf("Trident") > -1, //IE内核
      presto: u.indexOf("Presto") > -1, //opera内核
      webKit: u.indexOf("AppleWebKit") > -1, //苹果、谷歌内核
      gecko: u.indexOf("Gecko") > -1 && u.indexOf("KHTML") === -1, //火狐内核
      mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
      ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
      android: u.indexOf("Android") > -1 || u.indexOf("Linux") > -1, //android终端或者uc浏览器
      iPhone: u.indexOf("iPhone") > -1 || u.indexOf("Mac") > -1, //是否为iPhone或者QQHD浏览器
      iPad: u.indexOf("iPad") > -1, //是否iPad
      webApp: u.indexOf("Safari") === -1, //是否web应该程序，没有头部与底部
      weixin: u.indexOf("MicroMessenger") > -1,
      qq: u.match(/\sQQ/i) === " qq",
    };
  }

  //解析user-agent
  getUserAgentResult() {
    let uaData = UAParser(this.userAgent);
    let errorObj = {};
    let warnObj = {};
    let infoObj = {};

    //非手机端不一定能获取到 device  和 cpu 信息
    if (
      !uaData?.device?.model &&
      !uaData?.device?.type &&
      !uaData?.device?.model
    ) {
      if (this.deviceTypeAnalyse.mobile) {
        //移动端没有读取到设备信息，是有问题的
        warnObj["ua_device"] = "未获取设备信息";
      } else {
        //pc端读不到是正常的
        uaData.device.type = "desktop";
      }
    }

    if (!uaData?.cpu?.architecture) {
      if (this.deviceTypeAnalyse.mobile) {
        //移动端没有读取到cpu信息，是有问题的
        warnObj["ua_cpu_architecture"] = "未获取CPU信息";
      } else {
        //pc端读不到是正常的
        infoObj["ua_cpu_architecture"] = "非移动端可能无法获取此项数据";
      }
    }

    //错误
    if (!uaData?.browser?.name) {
      errorObj["ua_browser_name"] = "无法解析浏览器名称";
    }
    if (!uaData?.browser?.version) {
      errorObj["ua_browser_version"] = "无法解析浏览器版本";
    }
    if (!uaData?.engine?.name) {
      errorObj["ua_engine_name"] = "无法解析浏览器内核";
    }
    if (!uaData?.engine?.version) {
      errorObj["ua_engine_version"] = "无法解析浏览器内核版本";
    }
    if (!uaData?.os?.name) {
      errorObj["ua_os_name"] = "无法解析系统信息";
    }
    if (!uaData?.os?.version) {
      errorObj["ua_os_version"] = "无法解析系统版本";
    }

    return {
      uaInfo: uaData,
      errorObj: errorObj,
      warnObj: warnObj,
      infoObj: infoObj,
      errorNum: Object.keys(errorObj).length,
      warnNum: Object.keys(warnObj).length,
    };
  }

  //分析网络情况
  async getNetResult() {
    let netInfo = {};
    let errorObj = {};
    let warnObj = {};
    let infoObj = {};

    //请求服务器获取本机ip和经纬度、地区信息
    axios
      .get("/ip")
      .then(({ status, data }) => {
        console.log("ip信息", data);
      })
      .catch((error) => {
        console.log("出错了error", error);
      });

    if (true) {
      netInfo.addressMsg = {
        ip: "xx.xx.xx.xx",
        x: 127,
        y: 65,
        country: "xx",
        province: "xx",
        city: "xx",
      };
    } else {
      errorObj["net_addressMsg"] = "无法解析本机IP相关信息";
    }

    //请求服务器获取代理信息
    if (false) {
      netInfo.proxyMsg = {
        ip: "xx.xx.xx.xx",
        x: 55,
        y: 90,
        country: "xx",
        province: "xx",
        city: "xx",
      };
      errorObj["net_proxyMsg"] = "检测到代理信息";
    } else {
      netInfo.proxyMsg = null;
    }

    let pingResult = await this.getWebsiteArrayPing([
      {
        title: "国外",
        key: "google",
        url: "https://www.google.com",
      },
      {
        title: "国外",
        key: "facebook",
        url: "https://www.facebook.com",
      },
      {
        title: "国内",
        key: "baidu",
        url: "https://www.baidu.com",
      },
    ]);

    console.log(pingResult);
    let oversea = false;
    pingResult.forEach((item) => {
      if (!item.success) {
        warnObj["net_ping_" + item.key] = item.message;
      } else {
        if (item.key === "google" || item.key === "facebook") {
          oversea = true;
        }
      }
    });

    if (oversea) {
      netInfo.oversea = true;
    } else {
      netInfo.oversea = false;
      warnObj["net_oversea"] = "google 和 facebook 均无法访问";
    }

    netInfo.pingResult = pingResult;

    return {
      netInfo: netInfo,
      errorObj: errorObj,
      warnObj: warnObj,
      infoObj: infoObj,
      errorNum: Object.keys(errorObj).length,
      warnNum: Object.keys(warnObj).length,
    };
  }

  //同时ping多个
  async getWebsiteArrayPing(arr) {
    let allPing = [];
    arr.forEach((item) => {
      allPing.push(this.pingUrl(item));
    });

    return await Promise.all(allPing);
  }

  //判断网站是否可访问
  pingUrl(website) {
    return new Promise((resolve, reject) => {
      Webping(website.url)
        .then(function (delta) {
          // console.log('Ping time was ' + String(delta) + ' ms');
          resolve({
            title: website.title,
            url: website.url,
            key: website.key,
            success: true,
            delay: String(delta),
            message: "ping成功",
          });
        })
        .catch(function (err) {
          // console.error('Could not ping remote URL', err);
          resolve({
            title: website.title,
            url: website.url,
            key: website.key,
            success: false,
            message: String(err),
          });
        });
    });
  }

  //分析无头浏览器信息
  //参考网页：
  // https://blog.csdn.net/dianepure/article/details/90485609  原理
  // https://zhuanlan.zhihu.com/p/273405204       原理
  // https://infosimples.github.io/detect-headless/   判断
  async getHeadlessResult() {
    let headlessInfo = {};
    let errorObj = {};
    let warnObj = {};
    let infoObj = {};

    //1.判断ua中的信息
    if (/headless/i.test(this.userAgent)) {
      // console.log("Chrome headless detected");
      headlessInfo.us = "异常";
      errorObj["headless_us"] = "User-Agent中携带无头浏览器信息";
    } else {
      headlessInfo.us = "正确";
    }

    //2.webdriver
    if (navigator.webdriver) {
      headlessInfo.webdriver = "探测到webdriver信息";
      errorObj["headless_webdriver"] = "是无头浏览器";
    } else {
      headlessInfo.webdriver = "无webdriver信息，正确";
    }

    //3.插件 Plugins
    if (!navigator.plugins?.length) {
      headlessInfo.plugins = "插件数为0，不合理";
      warnObj["headless_plugins"] = "插件数为0，不合理";
    } else {
      let correctPrototypes =
        PluginArray.prototype === navigator.plugins.__proto__;
      correctPrototypes &= Plugin.prototype === navigator.plugins[0].__proto__;

      if (correctPrototypes) {
        headlessInfo.plugins = `插件数为 ${navigator.plugins?.length}`;
      } else {
        headlessInfo.plugins = "插件格式不对";
        errorObj["headless_plugins"] = "插件格式不对";
      }
    }

    //4.扩展 mimeTypes
    if (!navigator.mimeTypes?.length) {
      headlessInfo.mimeTypes = "扩展数为0，不合理";
      warnObj["headless_mimeTypes"] = "扩展数为0，不合理";
    } else {
      let correctPrototypes =
        MimeTypeArray.prototype === navigator.mimeTypes.__proto__;
      correctPrototypes &=
        MimeType.prototype === navigator.mimeTypes[0].__proto__;
      if (correctPrototypes) {
        headlessInfo.mimeTypes = `扩展数为 ${navigator.mimeTypes?.length}`;
      } else {
        headlessInfo.mimeTypes = "扩展格式不对";
        errorObj["headless_mimeTypes"] = "扩展格式不对";
      }
    }

    //5.Languages  语言
    if (!navigator.languages?.length || !navigator.language) {
      headlessInfo.languages = "缺少语言配置信息";
      errorObj["headless_languages"] = "异常";
    } else {
      headlessInfo.languages = `识别到 ${navigator.languages?.length} 种语言，正在使用 ${navigator.language}`;
    }

    //6.chrome信息
    if (window.chrome) {
      headlessInfo.chrome = "chrome 元素存在";
    } else {
      headlessInfo.chrome = "chrome 元素不存在";
      warnObj["headless_chrome"] = "chrome 元素不存在";
    }

    //7.Permission
    if (!navigator.permissions) {
      headlessInfo.permissions = "权限信息不存在";
      warnObj["headless_permissions"] = "可疑";
    } else {
      let permissionStatus = await navigator.permissions.query({
        name: "notifications",
      });
      try {
        /**
         * 无头浏览器 和  手机端浏览器 ，都没有Notification
         */

        let notificationPermission = Notification.permission;
        headlessInfo.permissions = `Permission："${permissionStatus.state}" ，notification permission："${notificationPermission}"`;
        // if (notificationPermission === "denied" && permissionStatus.state === "prompt"){
        //     warnObj["headless_permissions"] = "权限信息异常"
        // }
      } catch (e) {
        console.log("notification 异常", e);
        if (!this.isMobile()) {
          errorObj["headless_permissions"] = "通知权限异常";
        }
      }
    }

    //8.Devtool Protocol
    let usingDevTools = this.testDevtool();
    headlessInfo.devtool = usingDevTools
      ? "正在使用开发工具协议"
      : "没有使用开发工具协议";
    if (usingDevTools) {
      warnObj["headless_devtool"] = "可疑";
    }

    //9.Broken Image 加载失败的图片
    let brokenResult = await this.testImage();
    headlessInfo.brokenImage = brokenResult.message;
    if (!brokenResult.success) {
      errorObj["headless_brokenImage"] = "数据异常";
    }

    //10.Outer dimensions  外框大小
    let outerHeight = window.outerHeight;
    let outerWidth = window.outerWidth;
    headlessInfo.outer = `宽: ${outerWidth} px， 高: ${outerHeight} px`;
    if (outerHeight === 0 && outerWidth === 0) {
      errorObj["headless_outer"] = "浏览器外框大小异常";
    }

    //11.Test for connection-rtt  往返时延
    let connection = navigator.connection;
    let connectionRtt = connection ? connection.rtt : undefined;
    if (connectionRtt === undefined) {
      headlessInfo.connectionRtt = undefined;
      warnObj["headless_connectionRtt"] = "无信息";
    } else {
      headlessInfo.connectionRtt = connectionRtt + " ms";
      if (connectionRtt === 0 && !this.isMobile()) {
        errorObj["headless_connectionRtt"] = "往返时延值为 0，不正常";
      }
    }

    //12.图形驱动信息 webgl
    let canvas = document.createElement("canvas");
    let gl = canvas.getContext("webgl");

    let debugInfo = gl.getExtension("WEBGL_debug_renderer_info");
    let vendor = gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL);
    let renderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);

    headlessInfo.webgl = `vendor：${vendor}，renderer：${renderer}`;
    if (vendor === "Brian Paul" && renderer === "Mesa OffScreen") {
      errorObj["headless_webgl"] = "图形驱动信息不准确";
    }

    // 13、视频格式支持情况
    let videoElt = document.createElement("video");

    // console.log("-- video --", videoElt);
    console.log(
      "can play type",
      videoElt.canPlayType('video/mp4; codecs="avc1.42E01E"')
    );

    // const fingerprint = await fpCollect.generateFingerprint();
    // https://github.com/antoinevastel/fp-collect
    // console.log(fingerprint);
    headlessInfo.video_h264 = videoElt.canPlayType(
      'video/mp4; codecs="avc1.42E01E"'
    );

    let video_h264 = videoElt.canPlayType('video/mp4; codecs="avc1.42E01E"');

    if (video_h264 === "") {
      errorObj["headless_video_h264"] = "不支持h264";
    }

    return {
      headlessInfo: headlessInfo,
      errorObj: errorObj,
      warnObj: warnObj,
      infoObj: infoObj,
      errorNum: Object.keys(errorObj).length,
      warnNum: Object.keys(warnObj).length,
    };
  }

  //检查开发工具协议
  testDevtool() {
    const any = /./;
    let count = 0;
    let oldToString = any.toString;

    any.toString = function () {
      count++;
      return "any";
    };

    console.debug(any); //这句不要注释，是方法的一部分
    let usingDevTools = count > 1;
    any.toString = oldToString;
    return usingDevTools;
  }

  // Test for broken image
  testImage() {
    return new Promise((resolve, reject) => {
      let body = document.getElementsByTagName("body")[0];
      let image = document.createElement("img");
      image.src = "fake_image.png";
      image.style.opacity = 0;
      image.setAttribute("id", "fakeimage");
      body.appendChild(image);

      image.onerror = function () {
        if (image.width === 0 && image.height === 0) {
          resolve({
            success: false,
            message: `宽: 0 px，高: 0 px`,
          });
        } else {
          resolve({
            success: true,
            message: `宽: ${image.width} px，高: ${image.height} px`,
          });
        }
      };
    });
  }

  isMobile() {
    var userAgentInfo = navigator.userAgent;
    console.log(userAgentInfo);
    var mobileAgents = [
      "Android",
      "iPhone",
      "SymbianOS",
      "Windows Phone",
      "iPad",
      "iPod",
    ];
    var mobileFlag = false;
    for (var i = 0; i < mobileAgents.length; i++) {
      if (userAgentInfo.indexOf(mobileAgents[i]) > 0) {
        mobileFlag = true;
        break;
      }
    }
    return mobileFlag;
  }

  // 三方检测

  tengxuntianyu() {
    return new Promise((resolve, reject) => {
      function callback(res) {
        // 第一个参数传入回调结果，结果如下：
        // ret         Int       验证结果，0：验证成功。2：用户主动关闭验证码。
        // ticket      String    验证成功的票据，当且仅当 ret = 0 时 ticket 有值。
        // CaptchaAppId       String    验证码应用ID。
        // bizState    Any       自定义透传参数。
        // randstr     String    本次验证的随机串，后续票据校验时需传递该参数。
        console.log("callback:", res);

        // res（用户主动关闭验证码）= {ret: 2, ticket: null}
        // res（验证成功） = {ret: 0, ticket: "String", randstr: "String"}
        // res（请求验证码发生错误，验证码自动返回terror_前缀的容灾票据） = {ret: 0, ticket: "String", randstr: "String",  errorCode: Number, errorMessage: "String"}
        // 此处代码仅为验证结果的展示示例，真实业务接入，建议基于ticket和errorCode情况做不同的业务处理
        if (res.ret === 0) {
          //   infoObj["tengxun_ticket"] = "验证通过";
          resolve({ tengxun_ticket: "验证通过" });

          // 复制结果至剪切板
          // var str =
          //   "【randstr】->【" +
          //   res.randstr +
          //   "】      【ticket】->【" +
          //   res.ticket +
          //   "】";
          // var ipt = document.createElement("input");
          // ipt.value = str;
          // document.body.appendChild(ipt);
          // ipt.select();
          // document.execCommand("Copy");
          // document.body.removeChild(ipt);
          // alert(
          //   "1. 返回结果（randstr、ticket）已复制到剪切板，ctrl+v 查看。 2. 打开浏览器控制台，查看完整返回结果。"
          // );
        } else {
          //   warnObj["tengxun_ticket"] = "验证不通过";
          reject({ tengxun_ticket: "验证异常" });
        }
      }

      // 定义验证码js加载错误处理函数
      function loadErrorCallback() {
        var appid = "您的CaptchaAppId";
        // 生成容灾票据或自行做其它处理
        var ticket =
          "terror_1001_" +
          appid +
          "_" +
          Math.floor(new Date().getTime() / 1000);
        callback({
          ret: 0,
          randstr: "@" + Math.random().toString(36).substr(2),
          ticket: ticket,
          errorCode: 1001,
          errorMessage: "jsload_error",
        });
      }

      try {
        // 生成一个验证码对象
        // CaptchaAppId：登录验证码控制台，从【验证管理】页面进行查看。如果未创建过验证，请先新建验证。注意：不可使用客户端类型为小程序的CaptchaAppId，会导致数据统计错误。
        //callback：定义的回调函数
        var captcha = new TencentCaptcha("198273293", callback, {});
        // 调用方法，显示验证码
        captcha.show();
      } catch (error) {
        // 加载异常，调用验证码js加载错误处理函数
        loadErrorCallback();
      }
    });
  }

  wangyiyundun() {
    return new Promise((resolve, reject) => {
      console.log("wangyiyundun");
      try {
        var nef = createNEFingerprint({
          appId: "dc61550613f54a0498e0221988d89a0a",
          timeout: 6000,
        });
        nef.getToken().then((result) => {
          console.log(result);
          if (result.code === 200) {
            resolve(result);
            // 正常情况
            alert("get token:", result.token);

            const url = "https://fp-query.dun.163.com/v1/device/query";

            axios
              .post(url, { token: result.token })
              .then((res) => {
                console.log("设备ID", res);
              })
              .catch((err) => {
                console.error(err);
              });
          }
          if (result.code === 201) {
            // 离线模式
            reject(result);
            alert("get token:", result.token);
          }
        });
      } catch (err) {
        console.error(err);
      }
    });
  }

  async yunCheck() {
    let yunInfo = {};

    let errorObj = {};
    let warnObj = {};
    let infoObj = {};

    console.log("yunCheck");
    // 腾讯云检测
    await this.tengxuntianyu()
      .then((res) => {
        console.log("校验成功。。。", res);
        yunInfo = { tengxun: "pass" };
        infoObj = { ...infoObj, ...res };
      })
      .catch((err) => {
        console.log("校验成功。。。", err);
        yunInfo = {
          tengxun: "no pass",
        };
        errorObj = { ...errorObj, ...err };
      });

    // 网易云盾
    // await this.wangyiyundun()
    //   .then((res) => {
    //     console.log("----wang success ---");
    //     infoObj = { ...infoObj, ...res };
    //   })
    //   .catch((err) => {
    //     errorObj = { ...errorObj, ...err };
    //   });

    return {
      yunInfo: yunInfo,
      errorObj: errorObj,
      warnObj: warnObj,
      infoObj: infoObj,
      errorNum: Object.keys(errorObj).length,
      warnNum: Object.keys(warnObj).length,
    };
  }
}
export default new AnalyseHelp();
