import styles from './Flag.less';

/**
 * 根据国家编码，显示国旗
 * 
 * @param {*} props 
 * @returns 
 */
export default function Flag(props) {
  const { code } = props;

  return code ? getFlagIcon(code) : null;
}

/**
 * 获取国家国旗图标
 * @param {country_code} code
 * @returns view
 */
function getFlagIcon(code) {
  if (!code) {
    return null;
  }

  const url = `/images/flags/${code.toLowerCase()}.svg`;
  console.log(url);
  return (
    <span
      className={styles.flag}
      style={{ background: `url(${url})` }}
    ></span>
  );
}
