import { useState } from "react";
import styles from "./FunctionCheck.less";
import BaseDataFun from "../compnonet/BaseDataFun/BaseDataFun";
import OperationFun from "../compnonet/OperationFun/OperationFun";

/**
 * 功能检测
 *
 * @returns
 */
export default function FunctionCheck() {
  const [errorObj, setErrorObj] = useState({}); // 错误
  const [warnObj, setWarnObj] = useState({}); // 警告
  const [infoObj, setInfoObj] = useState({}); // 信息类

  return (
    <div className={styles.container}>
      {/* <BaseDataFun /> */}

      <OperationFun />
    </div>
  );
}

//  ui 分为 3部分  

//  1、 结果

//  2、 检测的选项

//  3、 评分


/**
 * 执行流程
 * 
 * 
 * 
 * 
 * a、获取浏览器，所有的信息  browser Info 
 *    浏览器内核
 *    cookie
 *    user-agent
 *    Canvas
 *    机器指纹
 * 
 * b、将重要的指标项，生成Task 
 *    -> 以检测的项，生成Task, task 是一个promise任务对象，执行完可返回执行结果 
 * 
 * c、任务执行完成， 更新任务 Task 状态
 * 
 * d、当task 全部完成后，更新 顶部的结果
 * 
 * e、给出 响应的评分
 * 
 */


/**
 * 浏览器内核
SunBrowser [Chrome 111]
User-Agent  Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_4) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/111.0.5563.64 Safari/537.36
时区        基于 IP 匹配
WebRTC      禁用
地理位置     [询问] 基于 IP 匹配
语言         基于 IP 匹配
分辨率        默认
字体         默认
Canvas       噪音
WebGL图像     噪音
WebGL元数据    Google Inc.(Intel Inc.) ANGLE (Intel, Intel(R) Iris(TM) Plus Graphics 650, OpenGL 4.1)
AudioContext  噪音
媒体设备       噪音
ClientRects   噪音
SpeechVoices  噪音
硬件并发数      4 核
设备内存       8 GB
设备名称       czdUxOsK-10289
MAC地址       f0:18:98:f2:a2:d5
Do Not Track   默认
Flash         关闭
端口扫描保护   [启用]
硬件加速        默认
 * 
 * 
 * 
 */




