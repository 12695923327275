import React, { useState } from 'react';

function ImageSelect() {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(URL.createObjectURL(file));
  };

  return (
    <div>
      <input type="file" accept="image/*" onChange={handleImageChange} />
      {selectedImage && <img src={selectedImage} alt="预览图片" style={{ maxWidth: '300px', maxHeight: '300px' }} />}
    </div>
  );
}

export default ImageSelect;
