import React, { useEffect } from "react";

function BaiduMap({ latitude, longitude }) {
  useEffect(() => {
    // 创建地图实例
    const map = new window.BMapGL.Map("mapContainer");

    // gps 转 百度坐标
    const baiduLocation = convertGPSToBaidu(latitude, longitude);
    // convertGPSToBaidu2(latitude, longitude);
    // 创建点坐标
    const point = new window.BMapGL.Point(longitude, latitude);
    // 初始化地图，设置中心点坐标和地图级别
    map.centerAndZoom(point, 15);
    // 添加标注
    const marker = new window.BMapGL.Marker(point);
    map.addOverlay(marker);

  }, [latitude, longitude]);

  return (
    <div id='mapContainer' style={{ width: "360px", height: "200px" }}></div>
  );
}

function convertGPSToBaidu(latitude, longitude) {
  var x_pi = (Math.PI * 3000.0) / 180.0;
  var x = longitude;
  var y = latitude;
  var z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * x_pi);
  var theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * x_pi);
  var baidu_longitude = z * Math.cos(theta) + 0.0065;
  var baidu_latitude = z * Math.sin(theta) + 0.006;
  return [baidu_latitude, baidu_longitude];
}


function convertGPSToBaidu2(latitude, longitude) {
  var convertUrl = 'https://api.map.baidu.com/geoconv/v1/?coords=' + longitude + ',' + latitude + '&from=1&to=5&ak=69bb6648b06f6a9cf426c119abe08221';

  fetch(convertUrl)
    .then(function(response) {
      return response.json();
    })
    .then(function(data) {
      if (data.status === 0) {
        var baiduLatitude = data.result[0].y;
        var baiduLongitude = data.result[0].x;
        console.log('Baidu Coordinates:', baiduLatitude, baiduLongitude);
      } else {
        console.log('Coordinate conversion failed.');
      }
    })
    .catch(function(error) {
      console.log('Error:', error);
    });
}

export default BaiduMap;
