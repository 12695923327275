export default {
    publicKey:
        "-----BEGIN PUBLIC KEY-----\n" +
        "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCwpTO3m/fWXnTsHR3h/uNGGvnx\n" +
        "uMMCHfQmib/Y2JgxdyZiRqbtEjjHmPtOjhfce2HkYgFeLpF7oD7WGwCbMvsAhM+w\n" +
        "VKwUAnSm/NQLOOUh3BEERNU4EoFu79EPlt8pdb6sIusLC44d/zTO1QOqlozIJbeI\n" +
        "ASAKUQUwRyb1W/bGFQIDAQAB\n" +
        "-----END PUBLIC KEY-----", //HttpTools 加密公钥；
    projectUniqueName: "WEB_VISITAMALYSE",//项目cookies存储用
    requestTimeout: 60000,  //接口请求超时(ms)
};
