import React, { Component } from "react";
import styles from "./App.less";
import { Spin, Result, Typography, Space, Descriptions, Tabs } from "antd";
import {
  CloseCircleFilled,
  WarningFilled,
  CheckCircleFilled,
  InfoCircleFilled,
} from "@ant-design/icons";
import TimeHelp from "../tool/TimeHelp";
import AnalyseHelp from "../tool/AnalyseHelp";
import { getIpAddressLocationInfo } from "../tool/IpAddress";
import Flag from "../compnonet/Flag/Flag";
import IpFlag from "../compnonet/Flag/IpFlag";
import FunctionCheck from './FunctionCheck';

const Paragraph = Typography.Paragraph;
const Item = Descriptions.Item;
const DescripOps = {
  bordered: true,
  column: 1,
  size: "small",
  labelStyle: { width: "1.3rem" },
};

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      upDate: 0,
      finish: false,

      //全部的错误和异常,信息
      errorObj: {},
      warnObj: {},
      infoObj: {},

      //基础
      baseInfo: null,

      //user-agent
      uaInfo: null,
      uaAnalyse: null,

      // IP Address
      myAddress: null,
      myLocationAddress: null,

      //网络
      netInfo: null,
      netAnalyse: null,

      //无头浏览器
      headlessInfo: null,
      headlessAnalyse: null,

      // 三方云检测
      yunInfo: null,

    };

    //最基础的信息，设备类型
    this.deviceTypeAnalyse = AnalyseHelp.deviceTypeAnalyse;
  }

  componentDidMount() {
    this.getBaseInfo();
    this.getUserAgentInfo();
    this.getNetInfo();
    this.getHeadlessInfo();

    this.getMyIpAddress();
    this.getMyLocationAddress();
    this.getYunCheckInfo();
  }

  refresh(cb) {
    this.setState(
      {
        upDate: this.state.upDate + 1,
      },
      cb
    );
  }

  async getMyIpAddress() {
    // 获取出口IP
  }

  async getMyLocationAddress() {
    getIpAddressLocationInfo((res) => {
      // console.log("getMyLocationAddress", res);

      this.setState({
        myAddress: res.myAddress,
        myAddressInfo: {
          errorNum: res.errorNum,
          warnNum: res.warnNum,
        },
        errorObj: Object.assign(this.state.errorObj, res.errorObj),
        warnObj: Object.assign(this.state.warnObj, res.warnObj),
        infoObj: Object.assign(this.state.infoObj, res.infoObj),
      });
    });
  }

  //分析基础信息
  async getBaseInfo() {
    let idCanvas = AnalyseHelp.getIdByCanvas();
    let cookie = await AnalyseHelp.getCookie();
    const indexDB = await AnalyseHelp.getIndexedDB();

    this.setState({
      baseInfo: {
        addtime: Date.now() - 86400000 * 3,
        uuid: "uuid_" + idCanvas,
        idCanvas: idCanvas,
        idFPrint: AnalyseHelp.getIdByFPrint(),
        cookie: cookie,
        storage: AnalyseHelp.getStorage(),
        sessionStorage: AnalyseHelp.getSessionStorage(),
        indexedDB: indexDB
      },
    });
  }

  //分析user-agent
  getUserAgentInfo() {
    let result = AnalyseHelp.getUserAgentResult();

    // console.log(result)
    this.setState({
      uaInfo: result.uaInfo,
      uaAnalyse: {
        errorNum: result.errorNum,
        warnNum: result.warnNum,
      },
      errorObj: Object.assign(this.state.errorObj, result.errorObj),
      warnObj: Object.assign(this.state.warnObj, result.warnObj),
      infoObj: Object.assign(this.state.infoObj, result.infoObj),
    });
  }

  //分析网络环境
  async getNetInfo() {
    let result = await AnalyseHelp.getNetResult();

    // console.log(result)
    this.setState({
      netInfo: result.netInfo,
      netAnalyse: {
        errorNum: result.errorNum,
        warnNum: result.warnNum,
      },
      errorObj: Object.assign(this.state.errorObj, result.errorObj),
      warnObj: Object.assign(this.state.warnObj, result.warnObj),
      infoObj: Object.assign(this.state.infoObj, result.infoObj),
    });
  }

  //检测无头浏览器
  async getHeadlessInfo() {
    let result = await AnalyseHelp.getHeadlessResult();

    // console.log(result)
    this.setState({
      headlessInfo: result.headlessInfo,
      headlessAnalyse: {
        errorNum: result.errorNum,
        warnNum: result.warnNum,
      },
      errorObj: Object.assign(this.state.errorObj, result.errorObj),
      warnObj: Object.assign(this.state.warnObj, result.warnObj),
      infoObj: Object.assign(this.state.infoObj, result.infoObj),
    });
  }

  async getYunCheckInfo() {

    let result = await AnalyseHelp.yunCheck();
    console.log('云检测', result)

    this.setState({
      yunCheckInfo: result.yunInfo,
      errorObj: Object.assign(this.state.errorObj, result.errorObj),
      warnObj: Object.assign(this.state.warnObj, result.warnObj),
      infoObj: Object.assign(this.state.infoObj, result.infoObj),
    })
  }

  getIpShowView(a, b) {
    if (!b) {
      return null;
    }

    if (a !== b) {
      return (
        <span>
          真实IP: {<IpFlag ip={b} />} {b}
        </span>
      );
    }

    return null;
  }

  render() {
    let {
      baseInfo,
      uaInfo,
      uaAnalyse,
      netInfo,
      netAnalyse,
      headlessInfo,
      headlessAnalyse,
      myAddress,
      myAddressInfo,
    } = this.state;


    return (
      <Tabs
        centered
        defaultActiveKey='1'
        onChange={() => { }}
        items={[
          {
            label: `浏览器环境检测`,
            key: "1",
            children: this.MainView(),
          },
          {
            label: `浏览器功能检测`,
            key: "2",
            children: <FunctionCheck />,
          },
        ]}
      />
    );
  }

  FunctionView() {
    return <div></div>
  }

  MainView() {
    let {
      baseInfo,
      uaInfo,
      uaAnalyse,
      netInfo,
      netAnalyse,
      headlessInfo,
      headlessAnalyse,
      myAddress,
      myAddressInfo,
      yunAnalyse,
      yunCheckInfo
    } = this.state;
    return (
      <div className={styles.wrapper}>
        <div className={styles.mainPage}>
          <div className={styles.pageTitle}>浏览器环境在线检测</div>
          <div className={styles.resultBox}>{this.getResultView()}</div>
          {/*<div className={styles.content}>*/}
          {/*    <div className={styles.contentTitle}>*/}
          {/*        <span>IP与设备ID分析</span>*/}
          {/*    </div>*/}
          {/*    <Descriptions*/}
          {/*        {...DescripOps}*/}
          {/*        layout={"vertical"}*/}
          {/*        column={{*/}
          {/*            xs: 1,*/}
          {/*            sm: 1,*/}
          {/*            md: 2*/}
          {/*        }}*/}
          {/*    >*/}
          {/*        <Item label={`此IP(xxx.xxx.xx.xx)下，存在设备 x 个`}>*/}
          {/*            <div className={styles.innerListBox}>*/}
          {/*                <Space>*/}
          {/*                    <div>xxxxxxx</div>*/}
          {/*                    <div>5分钟前(2022-12-12 12:00:12)</div>*/}
          {/*                </Space>*/}
          {/*                <Space>*/}
          {/*                    <div>xxxxxxx</div>*/}
          {/*                    <div>5分钟前(2022-12-12 12:00:12)</div>*/}
          {/*                </Space>*/}
          {/*            </div>*/}
          {/*        </Item>*/}
          {/*        <Item label={`此设备(xxxxxxxx)下，登录IP x 个`}>*/}
          {/*            <div className={styles.innerListBox}>*/}
          {/*                <Space>*/}
          {/*                    <div>xxxxxxx</div>*/}
          {/*                    <div>(美国-加州)</div>*/}
          {/*                    <div>5分钟前(2022-12-12 12:00:12)</div>*/}
          {/*                </Space>*/}
          {/*                <Space>*/}
          {/*                    <div>xxxxxxx</div>*/}
          {/*                    <div>(美国-加州)</div>*/}
          {/*                    <div>5分钟前(2022-12-12 12:00:12)</div>*/}
          {/*                    <div>距离:125公里</div>*/}
          {/*                </Space>*/}
          {/*                <Space>*/}
          {/*                    <div>xxxxxxx</div>*/}
          {/*                    <div>(美国-加州)</div>*/}
          {/*                    <div>5分钟前(2022-12-12 12:00:12)</div>*/}
          {/*                    <div>距离:125公里</div>*/}
          {/*                </Space>*/}
          {/*            </div>*/}
          {/*        </Item>*/}
          {/*    </Descriptions>*/}
          {/*</div>*/}
          <div className={styles.content}>
            <div className={styles.contentTitle}>
              <span>基本信息</span>
            </div>
            {baseInfo ? (
              <Descriptions {...DescripOps}>
                {/*<Item label={"本设备初始时间"}>*/}
                {/*    {baseInfo?.addtime && TimeHelp.getYMDHMS(baseInfo.addtime, true)}*/}
                {/*</Item>*/}
                <Item label={"设备唯一码"}>{baseInfo?.uuid}</Item>
                <Item label={"Canvas计算唯一码"}>{baseInfo?.idCanvas}</Item>
                <Item label={"FingerPrint计算唯一码"}>
                  {baseInfo?.idFPrint}
                </Item>
                <Item label={"cookie"}>
                  {baseInfo?.cookie}
                </Item>
                <Item label={"storage"}>
                  {baseInfo?.storage}
                </Item>
                <Item label={"sessionStorage"}>
                  {baseInfo?.sessionStorage}
                </Item>
                <Item label={"indexedDB"}>
                  {baseInfo?.indexedDB}
                </Item>
              </Descriptions>
            ) : (
              this.getLoadingView("解析中...")
            )}
          </div>
          <div className={styles.content}>
            <div className={styles.contentTitle}>
              <span>浏览器标识（UserAgent）</span>
              <Space>
                {uaAnalyse?.errorNum ? (
                  <span style={{ color: "red" }}>
                    {uaAnalyse?.errorNum}个错误
                  </span>
                ) : null}
                {uaAnalyse?.warnNum ? (
                  <span style={{ color: "darkorange" }}>
                    {uaAnalyse?.warnNum}个异常
                  </span>
                ) : null}
              </Space>
            </div>
            {uaInfo ? (
              <Descriptions {...DescripOps}>
                <Item label={"浏览器名称"}>
                  <div className={styles.betweenFlex}>
                    <div className={styles.flexBox}>
                      {this.getBrowserIcon(uaInfo?.browser?.name)}
                      {uaInfo?.browser?.name}
                    </div>
                    {this.getItemCheck("ua_browser_name")}
                  </div>
                </Item>
                <Item label={"浏览器版本"}>
                  <div className={styles.betweenFlex}>
                    <span>{uaInfo?.browser?.version}</span>
                    {this.getItemCheck("ua_browser_version")}
                  </div>
                </Item>
                <Item label={"浏览器内核"}>
                  <div className={styles.betweenFlex}>
                    <Space>
                      {uaInfo?.engine?.name}
                      {uaInfo?.engine?.version}
                    </Space>
                    {this.getItemCheck(["ua_engine_name", "ua_engine_version"])}
                  </div>
                </Item>
                <Item label={"原始UA值"}>
                  <div className={styles.betweenFlex}>
                    <span>{uaInfo?.ua}</span>
                    {uaAnalyse?.errorNum ? (
                      <CloseCircleFilled style={{ color: "red" }} />
                    ) : (
                      <CheckCircleFilled style={{ color: "limegreen" }} />
                    )}
                  </div>
                </Item>
                <Item label={"操作系统"}>
                  <div className={styles.betweenFlex}>
                    <div className={styles.flexBox}>
                      {this.getOSIcon(uaInfo?.os?.name)}
                      <Space>
                        {uaInfo?.os?.name}
                        {uaInfo?.os?.version}
                      </Space>
                    </div>
                    {this.getItemCheck(["ua_os_name", "ua_os_version"])}
                  </div>
                </Item>
                <Item label={"设备类型"}>
                  <div className={styles.betweenFlex}>
                    <div className={styles.flexBox}>
                      {this.getDevTypeIcon(uaInfo?.device?.type)}
                      {uaInfo?.device?.type}
                    </div>
                    {this.getItemCheck(["ua_device"])}
                  </div>
                </Item>
                <Item label={"CPU"}>
                  <div className={styles.betweenFlex}>
                    <span>{uaInfo?.cpu?.architecture}</span>
                    {this.getItemCheck(["ua_cpu_architecture"])}
                  </div>
                </Item>
              </Descriptions>
            ) : (
              this.getLoadingView("解析中...")
            )}
          </div>

          {/* 我的 IP 信息 */}
          <div className={styles.content}>
            <div className={styles.contentTitle}>
              <span>IP 信息</span>
              <Space>
                {myAddressInfo?.errorNum ? (
                  <span style={{ color: "red" }}>
                    {myAddressInfo?.errorNum}个错误
                  </span>
                ) : null}
                {myAddressInfo?.warnNum ? (
                  <span style={{ color: "darkorange" }}>
                    {myAddressInfo?.warnNum}个异常
                  </span>
                ) : null}
              </Space>
            </div>
            {myAddress ? (
              <Descriptions {...DescripOps}>
                <Item label={"IP地址"}>
                  <div className={styles.betweenFlex}>
                    <div className={styles.flexBox}>
                      <div>
                        <Flag code={myAddress?.ipInfo?.country_code} />
                        {myAddress?.ipInfo?.clientIp
                          ? "访问IP ：" + myAddress?.ipInfo?.clientIp
                          : ""}{" "}
                      </div>
                      <div>
                        {this.getIpShowView(
                          myAddress?.ipInfo?.clientIp,
                          myAddress.ipv4
                        )}
                      </div>
                    </div>
                    {this.getItemCheck("clientIp")}
                  </div>
                </Item>

                <Item label={"ip评分"}>
                  <div className={styles.betweenFlex}>
                    <div className={styles.flexBox}>
                      <span>评分：{this.getIpScoreView(myAddress?.ipInfo?.score)}</span>
                    </div>
                    {this.getItemCheck("ip_score")}
                  </div>
                </Item>

                {myAddress?.ipInfo?.continent ? (
                  <Item label={"地区"}>
                    <div className={styles.betweenFlex}>
                      <div className={styles.flexBox}>
                        {myAddress?.ipInfo?.continent}{" "}
                        <span>({myAddress?.ipInfo?.continent_code})</span>
                      </div>
                      {this.getItemCheck("continent")}
                    </div>
                  </Item>
                ) : null}

                {myAddress?.ipInfo?.country ? (
                  <Item label={"国家"}>
                    <div className={styles.betweenFlex}>
                      <div className={styles.flexBox}>
                        {this.getFlagIcon(myAddress?.ipInfo?.country_code)}{" "}
                        {myAddress?.ipInfo?.country}
                      </div>
                      {this.getItemCheck("country")}
                    </div>
                  </Item>
                ) : null}

                {myAddress?.ipInfo?.province ? (
                  <Item label={"城市"}>
                    <div className={styles.betweenFlex}>
                      <div className={styles.flexBox}>
                        {myAddress?.ipInfo?.province}{" "}
                        <span>({myAddress?.ipInfo?.city})</span>
                      </div>
                      {this.getItemCheck("continent")}
                    </div>
                  </Item>
                ) : null}

                {myAddress?.ipInfo?.tz ? (
                  <Item label={"时区"}>
                    <div className={styles.betweenFlex}>
                      <div className={styles.flexBox}>
                        {myAddress?.ipInfo?.tz}
                        <div>

                        </div>
                      </div>
                      {this.getItemCheck("tz")}
                    </div>
                  </Item>
                ) : null}

                <Item label={"位置坐标"}>
                  <div className={styles.betweenFlex}>
                    <div className={styles.flexBox}>
                      {myAddress?.ipInfo?.lat} - {myAddress?.ipInfo?.lng}
                    </div>
                    {this.getItemCheck("location")}
                  </div>
                </Item>
              </Descriptions>
            ) : (
              this.getLoadingView("解析中...")
            )}
          </div>

          {/* 网络 */}
          <div className={styles.content}>
            <div className={styles.contentTitle}>
              <span>网络</span>
              <Space>
                {netAnalyse?.errorNum ? (
                  <span style={{ color: "red" }}>
                    {netAnalyse?.errorNum}个错误
                  </span>
                ) : null}
                {netAnalyse?.warnNum ? (
                  <span style={{ color: "darkorange" }}>
                    {netAnalyse?.warnNum}个异常
                  </span>
                ) : null}
              </Space>
            </div>
            {netInfo ? (
              <Descriptions {...DescripOps}>
                <Item label={"可访问海外网"}>
                  <div className={styles.betweenFlex}>
                    <div className={styles.flexBox}>
                      {netInfo.oversea ? "可以" : "不能"}
                    </div>
                    {this.getItemCheck("net_oversea")}
                  </div>
                </Item>

                {netInfo.pingResult?.map((pingItem) => {
                  return (
                    <Item label={"访问" + pingItem.title} key={pingItem.key}>
                      <div className={styles.betweenFlex}>
                        <Space size={20}>
                          {pingItem.url}
                          {pingItem.success ? (
                            <span>
                              用时:
                              {this.getTimeDelayView(pingItem.delay)}
                            </span>
                          ) : (
                            <span style={{ color: "silver" }}>
                              {pingItem.message}
                            </span>
                          )}
                        </Space>
                        {this.getItemCheck("net_ping_" + pingItem.key)}
                      </div>
                    </Item>
                  );
                })}
              </Descriptions>
            ) : (
              this.getLoadingView("解析中...")
            )}
          </div>

          {/* 无头浏览器检测 */}
          <div className={styles.content}>
            <div className={styles.contentTitle}>
              <span>无头浏览器检测</span>
              <Space>
                {headlessAnalyse?.errorNum ? (
                  <span style={{ color: "red" }}>
                    {headlessAnalyse?.errorNum}个错误
                  </span>
                ) : null}
                {headlessAnalyse?.warnNum ? (
                  <span style={{ color: "darkorange" }}>
                    {headlessAnalyse?.warnNum}个异常
                  </span>
                ) : null}
              </Space>
            </div>
            {headlessInfo ? (
              <Descriptions {...DescripOps}>
                <Item label={"user-agent"}>
                  <div className={styles.betweenFlex}>
                    <div className={styles.flexBox}>{headlessInfo.us}</div>
                    {this.getItemCheck("headless_ua")}
                  </div>
                </Item>
                <Item label={"webdriver"}>
                  <div className={styles.betweenFlex}>
                    <div className={styles.flexBox}>
                      {headlessInfo.webdriver}
                    </div>
                    {this.getItemCheck("headless_webdriver")}
                  </div>
                </Item>
                <Item label={"插件"}>
                  <div className={styles.betweenFlex}>
                    <div className={styles.flexBox}>{headlessInfo.plugins}</div>
                    {this.getItemCheck("headless_plugins")}
                  </div>
                </Item>
                <Item label={"扩展"}>
                  <div className={styles.betweenFlex}>
                    <div className={styles.flexBox}>
                      {headlessInfo.mimeTypes}
                    </div>
                    {this.getItemCheck("headless_mimeTypes")}
                  </div>
                </Item>
                <Item label={"语言"}>
                  <div className={styles.betweenFlex}>
                    <div className={styles.flexBox}>
                      {headlessInfo.languages}
                    </div>
                    {this.getItemCheck("headless_languages")}
                  </div>
                </Item>
                <Item label={"chrome元素"}>
                  <div className={styles.betweenFlex}>
                    <div className={styles.flexBox}>{headlessInfo.chrome}</div>
                    {this.getItemCheck("headless_chrome")}
                  </div>
                </Item>
                <Item label={"权限"}>
                  <div className={styles.betweenFlex}>
                    <div className={styles.flexBox}>
                      {headlessInfo.permissions}
                    </div>
                    {this.getItemCheck("headless_permissions")}
                  </div>
                </Item>
                <Item label={"开发工具协议"}>
                  <div className={styles.betweenFlex}>
                    <div className={styles.flexBox}>{headlessInfo.devtool}</div>
                    {this.getItemCheck("headless_devtool")}
                  </div>
                </Item>
                <Item label={"异常图片大小"}>
                  <div className={styles.betweenFlex}>
                    <div className={styles.flexBox}>
                      {headlessInfo.brokenImage}
                    </div>
                    {this.getItemCheck("headless_brokenImage")}
                  </div>
                </Item>
                <Item label={"浏览器外框"}>
                  <div className={styles.betweenFlex}>
                    <div className={styles.flexBox}>{headlessInfo.outer}</div>
                    {this.getItemCheck("headless_outer")}
                  </div>
                </Item>
                <Item label={"往返时延"}>
                  <div className={styles.betweenFlex}>
                    <div className={styles.flexBox}>
                      {headlessInfo.connectionRtt}
                    </div>
                    {this.getItemCheck("headless_connectionRtt")}
                  </div>
                </Item>
                <Item label={"图形驱动信息"}>
                  <div className={styles.betweenFlex}>
                    <div className={styles.flexBox}>{headlessInfo.webgl}</div>
                    {this.getItemCheck("headless_webgl")}
                  </div>
                </Item>
                <Item label={"视频支持H264编码"}>
                  <div className={styles.betweenFlex}>
                    <div className={styles.flexBox}>
                      {headlessInfo.video_h264}
                    </div>
                    {this.getItemCheck("headless_video_h264")}
                  </div>
                </Item>
              </Descriptions>
            ) : (
              this.getLoadingView("解析中...")
            )}
          </div>

          {/* 三方检测 */}
          <div className={styles.content}>
            <div className={styles.contentTitle}>
              <span>云检测</span>
              <Space>
                {yunAnalyse?.errorNum ? (
                  <span style={{ color: "red" }}>
                    {yunAnalyse?.errorNum}个错误
                  </span>
                ) : null}
                {yunAnalyse?.warnNum ? (
                  <span style={{ color: "darkorange" }}>
                    {yunAnalyse?.warnNum}个异常
                  </span>
                ) : null}
              </Space>
            </div>
            {yunCheckInfo ? (
              <Descriptions {...DescripOps}>
                <Item label={"腾讯天御"}>
                  <div className={styles.betweenFlex}>
                    <div className={styles.flexBox}>
                      {yunCheckInfo.tengxun}
                    </div>
                    {this.getItemCheck("tengxun_ticket")}
                  </div>
                </Item>
              </Descriptions>
            ) : (
              this.getLoadingView("解析中...")
            )}
          </div>
        </div>
      </div>
    );
  }

  //位置视图
  getPositionView(positionData) {
    if (!positionData) {
      return "--";
    }
    return (
      <Space>
        <span>{positionData?.ip}</span>
        <span>
          ({positionData.country}-{positionData.province}-{positionData.city})
        </span>
        <span>经度:{positionData.x}</span>
        <span>纬度:{positionData.y}</span>
      </Space>
    );
  }

  //用时时间视图
  getTimeDelayView(num) {
    let color;

    if (num < 350) {
      color = "limegreen";
    } else if (num < 700) {
      color = "dodgerblue";
    } else if (num < 1000) {
      color = "darkorange";
    } else {
      color = "red";
    }

    return <span style={{ color: color }}>{num} ms</span>;
  }

  getIpScoreView(num) {
    let color;
    let tips;

    if (num <= 20 && num >= 0) {
      color = "limegreen";
      tips = '优质IP'
    } else if (num <= 50 && num > 20) {
      color = "dodgerblue";
      tips = '一般'
    } else if (num > 50 && num < 80) {
      color = "darkorange";
      tips = '疑似VPN'
    } else if (num >= 80 && num <= 100) {
      color = "red";
      tips = 'VPN'
    } else {
      color = "gray";
      tips = '评分未知'
    }

    return <span style={{ color: color }}>{num} <span style={{color: 'gray'}}>分</span> ( <span style={{ color: color}}>{tips}</span>)</span>;
  }

  //每一项判断是否错误
  getItemCheck(name) {
    if (Array.isArray(name)) {
      return this.getItemArrayCheck(name);
    } else {
      return this.getItemArrayCheck([name]);
    }
  }

  //多项判断是否错误
  getItemArrayCheck(nameArray) {
    let { errorObj, warnObj, infoObj } = this.state;

    let err, warn, info;

    nameArray.forEach((name) => {
      if (errorObj[name]) {
        err = errorObj[name];
      }

      if (warnObj[name]) {
        warn = warnObj[name];
      }

      if (infoObj[name]) {
        info = infoObj[name];
      }
    });

    if (err) {
      return (
        <Space style={{ color: "red" }}>
          <span>{err}</span>
          <CloseCircleFilled />
        </Space>
      );
    }

    if (warn) {
      return (
        <Space style={{ color: "darkorange" }}>
          <span>{warn}</span>
          <WarningFilled />
        </Space>
      );
    }

    if (info) {
      return (
        <Space style={{ color: "dodgerblue" }}>
          <span>{info}</span>
          <InfoCircleFilled />
        </Space>
      );
    }

    return <CheckCircleFilled style={{ color: "limegreen" }} />;
  }

  getLoadingView(text) {
    return (
      <div className={styles.resultItem}>
        <Space direction={"vertical"} align={"center"}>
          <Spin size={"large"} spinning={true} />
          {text}
        </Space>
      </div>
    );
  }

  //结果栏
  getResultView() {
    let { finish, uaInfo, netInfo, headlessInfo, errorObj, warnObj } =
      this.state;

    // console.log(uaInfo)
    // console.log(netInfo)
    // console.log(headlessInfo)
    if (!uaInfo || !netInfo || !headlessInfo) {
      return this.getLoadingView("检测中...");
    }

    //成功
    let errorKeys = Object.keys(errorObj);
    let warnKeys = Object.keys(warnObj);
    if (!errorKeys?.length && !warnKeys?.length) {
      return (
        <Result
          className={styles.resultItem}
          status='success'
          title='环境正常'
        />
      );
    }

    if (!errorKeys?.length) {
      //异常
      return (
        <Result
          className={styles.resultItem}
          status='warning'
          title='环境有异常'
          subTitle={<div>存在 {warnKeys?.length} 处异常</div>}
        >
          {/*<Paragraph>*/}
          {/*    <WarningFilled*/}
          {/*        style={{color: "red"}}*/}
          {/*    />*/}
          {/*    xxxxx 异常*/}
          {/*</Paragraph>*/}
        </Result>
      );
    }

    //错误
    return (
      <Result
        className={styles.resultItem}
        status='error'
        title='错误的环境'
        subTitle={
          <div>
            <div>存在 {errorKeys?.length} 处错误</div>
            <div>存在 {warnKeys?.length} 处异常</div>
          </div>
        }
      >
        {errorKeys.map((eachErrKey, index) => {
          return (
            <Paragraph key={index}>
              <CloseCircleFilled style={{ color: "red" }} />
              {errorObj[eachErrKey]}
            </Paragraph>
          );
        })}
      </Result>
    );
  }

  //浏览器图标
  getBrowserIcon(name) {
    if (!name) {
      return null;
    }

    let url = {
      chrome: require("../images/browser/google.png"),
      safari: require("../images/browser/safari.png"),
      ie: require("../images/browser/IE11.png"),
      opera: require("../images/browser/opera.png"),
      firefox: require("../images/browser/firefox.png"),
      edge: require("../images/browser/edge.jpeg"),
      "360 browser": require("../images/browser/360.jpeg"),
      qq: require("../images/browser/qq.jpg"),
      chromeium: require("../images/browser/chromeium.png"),
      electron: require("../images/browser/electron.png"),
      "2345explorer": require("../images/browser/2345.png"),
      wechat: require("../images/browser/wechat.jpeg"),
      ucbrowser: require("../images/browser/ucbrowser.png"),
      baidu: require("../images/browser/baidu.png"),
      "android browser": require("../images/browser/android.jpg"),
    }[name.toLowerCase()];

    if (!url) {
      return null;
    }

    return (
      <span
        className={styles.browserIcon}
        style={{ backgroundImage: `url(${url})` }}
      ></span>
    );
  }

  //设备类型图标
  getDevTypeIcon(name) {
    if (!name) {
      return null;
    }

    let url = {
      mobile: require("../images/devType/mobile.png"),
      tablet: require("../images/devType/tablet.png"),
      smarttv: require("../images/devType/smarttv.png"),
      desktop: require("../images/devType/desktop.png"),
    }[name.toLowerCase()];

    if (!url) {
      return null;
    }

    return (
      <span
        className={styles.browserIcon}
        style={{ backgroundImage: `url(${url})` }}
      ></span>
    );
  }

  //操作系统图标
  getOSIcon(name) {
    if (!name) {
      return null;
    }

    let n = name.toLowerCase();
    let url;
    if (n.indexOf("windows") !== -1) {
      url = require("../images/osIcon/windows-fill.png");
    } else if (n.indexOf("mac") !== -1) {
      url = require("../images/osIcon/mac.png");
    } else if (n.indexOf("android") !== -1) {
      url = require("../images/osIcon/android.png");
    }

    if (!url) {
      return null;
    }

    return (
      <span
        className={styles.browserIcon}
        style={{ backgroundImage: `url(${url})` }}
      ></span>
    );
  }

  /**
   * 获取国家国旗图标
   * @param {country_code} code
   * @returns view
   */
  getFlagIcon(code) {
    if (!code) {
      return null;
    }

    const url = `/images/flags/${code.toLowerCase()}.svg`;
    console.log(url);
    return (
      <span
        className={styles.flagsIcon}
        style={{ background: `url(${url})` }}
      ></span>
    );
  }
}

export default App;
